<template>
	<div class="dF py-4 aC w-full participant-card ">
		<div class="dF f1">
			<div class="participant-icon">
				<i :class="item.icon || `fe fe-user`" v-if="!item.invalid" />
				<a-tooltip overlayClassName="change-tooltip-color" v-else title="Missing info">
					<a-icon class="text-danger" type="warning" />
				</a-tooltip>
			</div>
			<div class="f1 pl-4">
				<span class="text-black">{{ item.name }}</span>
				<small class=block>{{ item.description || '' }}</small>
			</div>
		</div>
		<div class="dF">
			<div v-if="contactDetails" class="participant-action" @click="$emit('contact', item)">
				<a-icon type="user" />
			</div>
			<div v-if="detailsTab" class="participant-action" @click="$emit('view', item.id)">
				<i :class="item.icon || `fe fe-eye`" />
			</div>
			<template v-if="editingRights && !['completed', 'void'].includes(transaction.status)">
				<div class="participant-action" @click="$emit('edit', item.id)">
					<i :class="item.icon || `fe fe-edit-2`" />

				</div>
				<div class="participant-action" @click="$emit('trash', item.id)">
					<i :class="item.icon || `fe fe-trash-2`" />
				</div>
			</template>
			<template v-else-if="!['completed', 'void'].includes(transaction.status)">
				<a-tooltip overlayClassName="change-tooltip-color"
					title="This Participant is a signee of a contract, therefore cannot be edited">
					<div class="participant-action">
						<i class="fe fe-lock" />
					</div>
				</a-tooltip>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: ['item', 'valid', 'detailsTab', 'contactDetails'],
	computed: {
		editingRights() {
			let canEdit = false
			if (this.$p >= 40 && !this.item.locked) {
				return true
			} else {
				if (this.transaction.owners && this.transaction.owners.length) {
					this.transaction.owners.forEach(x => {
						if (x.id == this.user.id) {
							canEdit = true
						}
					})
					return canEdit
				}
				return true
			}
		},
		user() {
			return this.$store.state.user.user
		},
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		transaction() {
			if (this.$route.meta.reservation && this.reservation && this.reservation.id) {
				return this.reservation
			} else {
				return this.$store.state.appData.currentTransaction
			}
		},
	},
}
</script>

<style lang="scss">
.participant-icon,
.participant-action {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	place-items: center;
	place-content: center;
	font-size: 20px;
	color: currentColor;
}

.participant-icon {
	border: 1px solid currentColor;
}

.participant-card {
	border-bottom: 1px solid var(--light-purple);
}

.participant-action {
	cursor: pointer;

	&:focus,
	&:active {
		background: var(--light-purple);
	}

	&:hover {
		background: var(--very-light-purple);
	}

}
</style>
